import {Injectable} from '@angular/core';
import {CookieService} from 'angular2-cookie/core';
import {Router} from '@angular/router';
import {LiveUserModel} from '../../models/user/liveUser.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public liveUser: LiveUserModel = null;

  constructor(private cookies: CookieService,
              private router: Router) {
    this.getLiveUser();
  }

  private getLiveUser() {
    this.liveUser = this.cookies.getObject('user') as LiveUserModel;
  }

  private setLiveUser(liveUser: LiveUserModel) {
    this.liveUser = liveUser;
    this.cookies.putObject('user', liveUser);
  }

  /**
   * 是否已经登录
   */
  public isLogin(): boolean {
    this.getLiveUser();
    return !!this.liveUser;
  }

  /**
   * 保存用户信息
   * @param liveUser
   */
  public save(liveUser: LiveUserModel) {
    this.setLiveUser(liveUser);
  }

  /**
   * 登出
   */
  public logout() {

    // 清除数据
    this.clear();
    // 跳转登录
    this.router.navigate(['/pass/login']);
  }

  /**
   * 清空数据
   */
  private clear() {

    // 清除cookis
    this.cookies.removeAll();

    // 清除内存数据
    this.liveUser = null;
  }
}
