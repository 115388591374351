import {NgModule, Provider} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SystemService} from './system.service';
import {HttpService} from './http.service';
import {CookieOptions, CookieService} from 'angular2-cookie/core';
import {UserService} from './user.service';

/**
 * 服务需要发布提供(providers)
 */
const PROVIDERS: Provider[] = [
  SystemService,
  HttpService,
  UserService,
  CookieService,
  {
    /**
     * bug https://github.com/salemdar/angular2-cookie/issues/37
     * StaticInjectorError(Platform: core)[e -> e]: NullInjectorError: No provider for e!
     */
    provide: CookieOptions,
    useValue: {}
  }
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: PROVIDERS
})
export class ServiceModule {
}
