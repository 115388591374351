/**
 * 拦截器入口文件
 */
import {Provider} from '@angular/core/src/di/provider';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ZzHttpInterceptor} from './zz-http-interceptor';

/**
 * 创建拦截器工具类
 */
export const InterceptorProviders: Provider[] = [
  {provide: HTTP_INTERCEPTORS, useClass: ZzHttpInterceptor, multi: true},
];

