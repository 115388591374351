import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PassportComponent} from './passport/passport.component';
import {DefaultComponent} from './default/default.component';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {RouterModule} from '@angular/router';
import {HeaderComponent} from './default/header/header.component';
import {SidebarComponent} from './default/sidebar/sidebar.component';
import {UserComponent} from './default/header/user/user.component';
import {TitleComponent} from './default/title/title.component';

const COMPONENTS: Array<any> = [
  PassportComponent,
  DefaultComponent,
  HeaderComponent,
  UserComponent,
  SidebarComponent,
  TitleComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    // AntDes模块
    NgZorroAntdModule,
  ],
  declarations: COMPONENTS
})
export class LayoutModule {
}
