/**
 * 负责导入模块
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RoutesRoutingModule} from './routes-routing.module';
import {LayoutModule} from '../layout/layout.module';
import {NgZorroAntdModule} from 'ng-zorro-antd';

@NgModule({
  imports: [
    CommonModule,
    // 视口模块
    LayoutModule,
    // 设置根路由
    RoutesRoutingModule
  ],
  declarations: []
})
export class RoutesModule {
}
