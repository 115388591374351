import {Directive, ElementRef, Input, OnChanges} from '@angular/core';
// import {isNullOrUndefined} from 'util';

@Directive({
  selector: 'div[zzSrc]'
})
export class ZzSrcDirective implements OnChanges {

  @Input() zzSrc: string;

  constructor(private el: ElementRef) {
  }

  // 监听输入属性的变化
  ngOnChanges() {
    this.setImageUrl(this.zzSrc);
  }

  private setImageUrl(url: string) {

    /**
     * background-position: center center;
     * background-size: cover;
     */
    this.el.nativeElement.style.background = 'url(' + url + ') no-repeat center';
    this.el.nativeElement.style.backgroundSize = 'contain';
  }

}
