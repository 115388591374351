import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../../core/service/user.service';

@Component({
  selector: 'layout-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  constructor(public user: UserService) { }

  ngOnInit() {
  }

}
