import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, Subscriber, Subscription} from 'rxjs';
import {HttpModel} from '../../models/http.model';
import {SpinModel} from '../../models/spin.model';
import {tap} from 'rxjs/operators';
import {ProgressModel} from '../../models/progress.model';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) {
  }

  /**
   * post请求
   *
   * @param url 请求路径
   * @param params 请求参数
   */
  post(url: string, params: { [key: string]: string } = {}): Observable<HttpModel> {
    /**
     * 返回观察流
     */
    return <Observable<HttpModel>>  Observable.create((observer: Subscriber<HttpModel>) => {

      // 创建form表单
      const formData: FormData = new FormData();

      // 填充数据
      Object.keys(params).map(key => {

        // 控制传递空字符串
        formData.append(key, !!params[key] ? params[key] : '');
      });

      this.http.post<HttpModel>(
        url,
        formData,
        {
          // session登录
          withCredentials: true
        })
        .pipe(tap(
          null,
          error => observer.complete && observer.complete()
        )).subscribe(
        next => observer.next && observer.next(next),
        error => observer.error && observer.error(error),
        () => observer.complete && observer.complete()
      );
    });
  }

  /**
   * 包含进度的post请求
   *
   * @param url 路径
   * @param option 请求回调
   * @param params 请求参数
   */
  spin(url: string,
       option: SpinModel,
       params: { [key: string]: string } = {}) {

    option.loading && option.loading(true);

    // 发送网络请求
    this.post(url, params)
      .pipe(tap(
        (event: any) => {
          // 事件为响应
          if (event instanceof HttpResponse) {
            option.loading && option.loading(false);
          }
        },
        (error: HttpErrorResponse) => option.loading && option.loading(false),
        () => option.loading && option.loading(false)
      ))
      .subscribe(
        (event: HttpModel) => option.data(event.data),
        error => option.error && option.error(error),
        () => option.complete && option.complete()
      );
  }

  /**
   * 自定义上传请求
   *
   * https://segmentfault.com/a/1190000010259536
   * https://segmentfault.com/a/1190000011174923
   * https://www.jianshu.com/p/eaba1f2afa70
   *
   * 返回值用于取消上传
   */
  upload(url: string, file: any, progress: ProgressModel): Subscription {

    // 创建请求
    const request = new HttpRequest('PUT', url, file, {
      reportProgress: true,
    });

    /**
     * 创建观察对象
     */
    const _: Observable<HttpEvent<any>> = this.http.request(request);

    /**
     * 返回订阅 便于取消
     */
    return _.subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          // Upload progress event
          progress.onProgress({
            percent: event.loaded / event.total * 100
          });
        } else if (event.type === HttpEventType.Response) {
          // response received...
          progress.onSuccess(event.body);
        }
      },
      err => progress.onError(err)
    );
  }

  /**
   * get请求
   *
   * @param url 请求路径
   */
  get(url: string): Observable<any> {
    // 返回观察流
    return <Observable<HttpModel>>Observable.create((observer: Subscriber<HttpModel>) => {

      this.http.get<HttpModel>(url as string)
        .pipe(tap(
          null,
          () => observer.complete && observer.complete()
        )).subscribe(
        next => observer.next && observer.next(next),
        error => observer.error && observer.error(error),
        () => observer.complete && observer.complete()
      );
    });
  }
}
