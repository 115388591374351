/**
 * 网络请求拦截器
 *
 * https://angular.cn/guide/http#making-a-post-request
 */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs-compat';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {HttpModel} from '../../models/http.model';
import {NzMessageService} from 'ng-zorro-antd';
import {UserService} from '../service/user.service';
import { environment } from '../../../environments/environment';


@Injectable()
export class ZzHttpInterceptor implements HttpInterceptor {

  constructor(private msg: NzMessageService,
              private user: UserService
  ) {

  }

  /**
   * 重载拦截器
   *
   * @param req 请求体
   * @param next 请求体/响应体
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // 获取真是url
    const finalReq: HttpRequest<any> = this.getFinalReq(req);

    // 发送求情并拦截
    return next.handle(finalReq).pipe(
      tap(
        (event: any) => {
          if (event instanceof HttpResponse && !!event.body) this.successMessage(event.body);
        },
        (err: HttpErrorResponse) => this.errorMessage(err)
      )
    );
  }

  /**
   * 获取真是发送请求
   */
  getFinalReq(req: HttpRequest<any>): HttpRequest<any> {

    /**
     * 获取URL
     */
    let url = req.url;

    /**
     * 添加访问host
     */
    if (!/^https?:\/\//i.test(url)) url = environment.BASE_URL + url;

    return req.clone({
      url: url
    });
  }


  /**
   * 成功信息
   */
  successMessage(http: HttpModel) {
    // TODO: 成功信息展示
  }

  /**
   * 失败信息
   */
  errorMessage(err: HttpErrorResponse) {

    /**
     * 返回错误
     */
    this.msg.error((<HttpModel>err.error).msg || (<HttpErrorResponse>err).message);

    /**
     * 賬號已過期
     */
    if ((<HttpModel>err.error).msgCode && (<HttpModel>err.error).msgCode === 1001) {
      this.user.logout();
    }
  }
}
