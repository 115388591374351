/**
 * 只负责路由
 */
import {NgModule} from '@angular/core';
import {Routes, RouterModule, Route} from '@angular/router';
import {PassportComponent} from '../layout/passport/passport.component';
import {LayoutModule} from '../layout/layout.module';
import {DefaultComponent} from '../layout/default/default.component';
import {AuthGuard} from '../core/guard/auth.guard';

/**
 * 默认路由 -> 直播模块
 */
const DEFULT_ROUTE: Route = {
  path: '', // 根路径
  component: DefaultComponent,
  canActivate: [AuthGuard],
  canActivateChild: [AuthGuard],
  children: [
    {
      // 根路径
      path: '',
      redirectTo: 'live',
      pathMatch: 'full',
    },
    {
      // live路径
      path: 'live',
      loadChildren: './live/live.module#LiveModule'
    }
  ]
};

/**
 * 登录路由
 */
const LIVE_ROUTE: Route = {
  path: 'pass',
  component: PassportComponent,
  loadChildren: './pass/pass.module#PassModule'
};

/**
 * 404路由 -> 默认路由
 */
const NOT_FOUND_ROUTE: Route = {
  path: '**',
  redirectTo: ''
};


const routes: Routes = [
  DEFULT_ROUTE,
  LIVE_ROUTE,
  NOT_FOUND_ROUTE
];

@NgModule({
  imports: [
    LayoutModule,
    // 使用Hash链接
    RouterModule.forRoot(routes, {useHash: true}),
  ],
  exports: [RouterModule]
})
export class RoutesRoutingModule {
}
