import {NgModule, Type} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClipboardDirective} from './clipboard.directive';
import {ZzSrcDirective} from './zz-src.directive';

const DIRECTIVES: Array<Type<any> | any[]> = [
  ClipboardDirective,
  ZzSrcDirective
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: DIRECTIVES,
  exports: DIRECTIVES
})
export class DirectiveModule {
}
