import {CountryModel} from '../models/country.model';

/**
 * 国家与地区
 */
export const COUNTRYS: CountryModel[] = [
  {en: 'Angola', cn: '安哥拉', area: '244'},
  {en: 'Afghanistan', cn: '阿富汗', area: '93'},
  {en: 'Albania', cn: '阿爾巴尼亞', area: '355'},
  {en: 'Algeria', cn: '阿爾及利亞', area: '213'},
  {en: 'Andorra', cn: '安道爾共和國', area: '376'},
  {en: 'Anguilla', cn: '安圭拉島', area: '1264'},
  {en: 'Antigua and Barbuda', cn: '安提瓜和巴布達', area: '1268'},
  {en: 'Argentina', cn: '阿根廷', area: '54'},
  {en: 'Armenia', cn: '亞美尼亞', area: '374'},
  {en: 'Ascension', cn: '阿森松', area: '247'},
  {en: 'Australia', cn: '澳大利亞', area: '61'},
  {en: 'Austria', cn: '奧地利', area: '43'},
  {en: 'Azerbaijan', cn: '阿塞拜疆', area: '994'},
  {en: 'Bahamas', cn: '巴哈馬', area: '1242'},
  {en: 'Bahrain', cn: '巴林', area: '973'},
  {en: 'Bangladesh', cn: '孟加拉國', area: '880'},
  {en: 'Barbados', cn: '巴巴多斯', area: '1246'},
  {en: 'Belarus', cn: '白俄羅斯', area: '375'},
  {en: 'Belgium', cn: '比利時', area: '32'},
  {en: 'Belize', cn: '伯利茲', area: '501'},
  {en: 'Benin', cn: '貝寧', area: '229'},
  {en: 'Bermuda Is.', cn: '百慕大群島', area: '1441'},
  {en: 'Bolivia', cn: '玻利維亞', area: '591'},
  {en: 'Botswana', cn: '博茨瓦納', area: '267'},
  {en: 'Brazil', cn: '巴西', area: '55'},
  {en: 'Brunei', cn: '文萊', area: '673'},
  {en: 'Bulgaria', cn: '保加利亞', area: '359'},
  {en: 'Burkina-faso', cn: '布基納法索', area: '226'},
  {en: 'Burma', cn: '緬甸', area: '95'},
  {en: 'Burundi', cn: '布隆迪', area: '257'},
  {en: 'Cameroon', cn: '喀麥隆', area: '237'},
  {en: 'Canada', cn: '加拿大', area: '1'},
  {en: 'Cayman Is.', cn: '開曼群島', area: '1345'},
  {en: 'Central African Republic', cn: '中非共和國', area: '236'},
  {en: 'Chad', cn: '乍得', area: '235'},
  {en: 'Chile', cn: '智利', area: '56'},
  {en: 'China', cn: '大陸', area: '86'},
  {en: 'Colombia', cn: '哥倫比亞', area: '57'},
  {en: 'Congo', cn: '剛果', area: '242'},
  {en: 'Cook Is.', cn: '庫克群島', area: '682'},
  {en: 'Costa Rica', cn: '哥斯達黎加', area: '506'},
  {en: 'Cuba', cn: '古巴', area: '53'},
  {en: 'Cyprus', cn: '塞浦路斯', area: '357'},
  {en: 'Czech Republic', cn: '捷克', area: '420'},
  {en: 'Denmark', cn: '丹麥', area: '45'},
  {en: 'Djibouti', cn: '吉布提', area: '253'},
  {en: 'Dominica Rep.', cn: '多米尼加共和國', area: '1890'},
  {en: 'Ecuador', cn: '厄瓜多爾', area: '593'},
  {en: 'Egypt', cn: '埃及', area: '20'},
  {en: 'EI Salvador', cn: '薩爾瓦多', area: '503'},
  {en: 'Estonia', cn: '愛沙尼亞', area: '372'},
  {en: 'Ethiopia', cn: '埃塞俄比亞', area: '251'},
  {en: 'Fiji', cn: '斐濟', area: '679'},
  {en: 'Finland', cn: '芬蘭', area: '358'},
  {en: 'France', cn: '法國', area: '33'},
  {en: 'French Guiana', cn: '法屬圭亞那', area: '594'},
  {en: 'Gabon', cn: '加蓬', area: '241'},
  {en: 'Gambia', cn: '岡比亞', area: '220'},
  {en: 'Georgia', cn: '格魯吉亞', area: '995'},
  {en: 'Germany', cn: '德國', area: '49'},
  {en: 'Ghana', cn: '加納', area: '233'},
  {en: 'Gibraltar', cn: '直布羅陀', area: '350'},
  {en: 'Greece', cn: '希臘', area: '30'},
  {en: 'Grenada', cn: '格林納達', area: '1809'},
  {en: 'Guam', cn: '關島', area: '1671'},
  {en: 'Guatemala', cn: '危地馬拉', area: '502'},
  {en: 'Guinea', cn: '幾內亞', area: '224'},
  {en: 'Guyana', cn: '圭亞那', area: '592'},
  {en: 'Haiti', cn: '海地', area: '509'},
  {en: 'Honduras', cn: '洪都拉斯', area: '504'},
  {en: 'Hongkong', cn: '香港', area: '852'},
  {en: 'Hungary', cn: '匈牙利', area: '36'},
  {en: 'Iceland', cn: '冰島', area: '354'},
  {en: 'India', cn: '印度', area: '91'},
  {en: 'Indonesia', cn: '印度尼西亞', area: '62'},
  {en: 'Iran', cn: '伊朗', area: '98'},
  {en: 'Iraq', cn: '伊拉克', area: '964'},
  {en: 'Ireland', cn: '愛爾蘭', area: '353'},
  {en: 'Israel', cn: '以色列', area: '972'},
  {en: 'Italy', cn: '意大利', area: '39'},
  {en: 'Ivory Coast', cn: '科特迪瓦', area: '225'},
  {en: 'Jamaica', cn: '牙買加', area: '1876'},
  {en: 'Japan', cn: '日本', area: '81'},
  {en: 'Jordan', cn: '約旦', area: '962'},
  {en: 'Kampuchea (Cambodia )', cn: '柬埔寨', area: '855'},
  {en: 'Kazakstan', cn: '哈薩克斯坦', area: '327'},
  {en: 'Kenya', cn: '肯尼亞', area: '254'},
  {en: 'Korea', cn: '韓國', area: '82'},
  {en: 'Kuwait', cn: '科威特', area: '965'},
  {en: 'Kyrgyzstan', cn: '吉爾吉斯坦', area: '331'},
  {en: 'Laos', cn: '老撾', area: '856'},
  {en: 'Latvia', cn: '拉脫維亞', area: '371'},
  {en: 'Lebanon', cn: '黎巴嫩', area: '961'},
  {en: 'Lesotho', cn: '萊索托', area: '266'},
  {en: 'Liberia', cn: '利比里亞', area: '231'},
  {en: 'Libya', cn: '利比亞', area: '218'},
  {en: 'Liechtenstein', cn: '列支敦士登', area: '423'},
  {en: 'Lithuania', cn: '立陶宛', area: '370'},
  {en: 'Luxembourg', cn: '盧森堡', area: '352'},
  {en: 'Macao', cn: '澳門', area: '853'},
  {en: 'Madagascar', cn: '馬達加斯加', area: '261'},
  {en: 'Malawi', cn: '馬拉維', area: '265'},
  {en: 'Malaysia', cn: '馬來西亞', area: '60'},
  {en: 'Maldives', cn: '馬爾代夫', area: '960'},
  {en: 'Mali', cn: '馬里', area: '223'},
  {en: 'Malta', cn: '馬耳他', area: '356'},
  {en: 'Mariana Is', cn: '馬里亞那群島', area: '1670'},
  {en: 'Martinique', cn: '馬提尼克', area: '596'},
  {en: 'Mauritius', cn: '毛里求斯', area: '230'},
  {en: 'Mexico', cn: '墨西哥', area: '52'},
  {en: 'Moldova, Republic of', cn: '摩爾多瓦', area: '373'},
  {en: 'Monaco', cn: '摩納哥', area: '377'},
  {en: 'Mongolia', cn: '蒙古', area: '976'},
  {en: 'Montserrat Is', cn: '蒙特塞拉特島', area: '1664'},
  {en: 'Morocco', cn: '摩洛哥', area: '212'},
  {en: 'Mozambique', cn: '莫桑比克', area: '258'},
  {en: 'Namibia', cn: '納米比亞', area: '264'},
  {en: 'Nauru', cn: '瑙魯', area: '674'},
  {en: 'Nepal', cn: '尼泊爾', area: '977'},
  {en: 'Netheriands Antilles', cn: '荷屬安的列斯', area: '599'},
  {en: 'Netherlands', cn: '荷蘭', area: '31'},
  {en: 'New Zealand', cn: '新西蘭', area: '64'},
  {en: 'Nicaragua', cn: '尼加拉瓜', area: '505'},
  {en: 'Niger', cn: '尼日爾', area: '227'},
  {en: 'Nigeria', cn: '尼日利亞', area: '234'},
  {en: 'North Korea', cn: '朝鮮', area: '850'},
  {en: 'Norway', cn: '挪威', area: '47'},
  {en: 'Oman', cn: '阿曼', area: '968'},
  {en: 'Pakistan', cn: '巴基斯坦', area: '92'},
  {en: 'Panama', cn: '巴拿馬', area: '507'},
  {en: 'Papua New Cuinea', cn: '巴布亞新幾內亞', area: '675'},
  {en: 'Paraguay', cn: '巴拉圭', area: '595'},
  {en: 'Peru', cn: '秘魯', area: '51'},
  {en: 'Philippines', cn: '菲律賓', area: '63'},
  {en: 'Poland', cn: '波蘭', area: '48'},
  {en: 'French Polynesia', cn: '法屬玻利尼西亞', area: '689'},
  {en: 'Portugal', cn: '葡萄牙', area: '351'},
  {en: 'Puerto Rico', cn: '波多黎各', area: '1787'},
  {en: 'Qatar', cn: '卡塔爾', area: '974'},
  {en: 'Reunion', cn: '留尼旺', area: '262'},
  {en: 'Romania', cn: '羅馬尼亞', area: '40'},
  {en: 'Russia', cn: '俄羅斯', area: '7'},
  {en: 'Saint Lueia', cn: '聖盧西亞', area: '1758'},
  {en: 'Saint Vincent', cn: '聖文森特島', area: '1784'},
  {en: 'Samoa Eastern', cn: '東薩摩亞(美)', area: '684'},
  {en: 'Samoa Western', cn: '西薩摩亞', area: '685'},
  {en: 'San Marino', cn: '聖馬力諾', area: '378'},
  {en: 'Sao Tome and Principe', cn: '聖多美和普林西比', area: '239'},
  {en: 'Saudi Arabia', cn: '沙特阿拉伯', area: '966'},
  {en: 'Senegal', cn: '塞內加爾', area: '221'},
  {en: 'Seychelles', cn: '塞舌爾', area: '248'},
  {en: 'Sierra Leone', cn: '塞拉利昂', area: '232'},
  {en: 'Singapore', cn: '新加坡', area: '65'},
  {en: 'Slovakia', cn: '斯洛伐克', area: '421'},
  {en: 'Slovenia', cn: '斯洛文尼亞', area: '386'},
  {en: 'Solomon Is', cn: '所羅門群島', area: '677'},
  {en: 'Somali', cn: '索馬里', area: '252'},
  {en: 'South Africa', cn: '南非', area: '27'},
  {en: 'Spain', cn: '西班牙', area: '34'},
  {en: 'Sri Lanka', cn: '斯里蘭卡', area: '94'},
  {en: 'St.Lucia', cn: '聖盧西亞', area: '1758'},
  {en: 'St.Vincent', cn: '聖文森特', area: '1784'},
  {en: 'Sudan', cn: '蘇丹', area: '249'},
  {en: 'Suriname', cn: '蘇里南', area: '597'},
  {en: 'Swaziland', cn: '斯威士蘭', area: '268'},
  {en: 'Sweden', cn: '瑞典', area: '46'},
  {en: 'Switzerland', cn: '瑞士', area: '41'},
  {en: 'Syria', cn: '敘利亞', area: '963'},
  {en: 'Taiwan', cn: '台灣', area: '886'},
  {en: 'Tajikstan', cn: '塔吉克斯坦', area: '992'},
  {en: 'Tanzania', cn: '坦桑尼亞', area: '255'},
  {en: 'Thailand', cn: '泰國', area: '66'},
  {en: 'Togo', cn: '多哥', area: '228'},
  {en: 'Tonga', cn: '湯加', area: '676'},
  {en: 'Trinidad and Tobago', cn: '特立尼達和多巴哥', area: '1809'},
  {en: 'Tunisia', cn: '突尼斯', area: '216'},
  {en: 'Turkey', cn: '土耳其', area: '90'},
  {en: 'Turkmenistan', cn: '土庫曼斯坦', area: '993'},
  {en: 'Uganda', cn: '烏乾達', area: '256'},
  {en: 'Ukraine', cn: '烏克蘭', area: '380'},
  {en: 'United Arab Emirates', cn: '阿拉伯聯合酋長國', area: '971'},
  {en: 'United Kiongdom', cn: '英國', area: '44'},
  {en: 'United States of America', cn: '美國', area: '1'},
  {en: 'Uruguay', cn: '烏拉圭', area: '598'},
  {en: 'Uzbekistan', cn: '烏茲別克斯坦', area: '233'},
  {en: 'Venezuela', cn: '委內瑞拉', area: '58'},
  {en: 'Vietnam', cn: '越南', area: '84'},
  {en: 'Yemen', cn: '也門', area: '967'},
  {en: 'Yugoslavia', cn: '南斯拉夫', area: '381'},
  {en: 'Zimbabwe', cn: '津巴布韋', area: '263'},
  {en: 'Zaire', cn: '扎伊爾', area: '243'},
  {en: 'Zambia', cn: '贊比亞', area: '260'}
];
