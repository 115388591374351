import {NgModule, Provider} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthGuard} from './auth.guard';

const PROVIDERS: Provider[] = [
  AuthGuard
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: PROVIDERS
})
export class GuardModule {
}
