import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../service/user.service';
import {NzMessageService} from 'ng-zorro-antd';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private user: UserService,
    private msg: NzMessageService
  ) {
  }

  /**
   * 主路由守卫
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.isLogin();
  }

  /**
   * 子路由守衛
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.isLogin();
  }

  /**
   * 是否为登录状态
   */
  isLogin(): boolean {

    // 通過權限
    if (this.user.isLogin()) {
      return true;
    }

    // 權限不足
    this.msg.warning('未登入，请先登入');

    // 權限跳轉
    this.router.navigate(['/pass/login']);

    return false;
  }

}
