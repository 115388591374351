import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {SYSTEM_GET_POLICY_OBJECT, SYSTEM_GET_UPLOAD_LIVE_COVER_URL} from '../../urls/system.url';
import {COUNTRYS} from '../../consts/country.const';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(private http: HttpService) {
  }

  countryTransform(value: string): string {

    const country = COUNTRYS.find((val) => {
      return val.area === value;
    });

    return country ? country.cn : '未知地域';
  }
}
