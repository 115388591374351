import {Directive, ElementRef, OnDestroy, OnInit} from '@angular/core';

@Directive({
  selector: '[clipboard]'
})
export class ClipboardDirective implements OnInit, OnDestroy {

  constructor(private eltRef: ElementRef) {
  }

  ngOnInit() {
    console.log('------');
  }

  ngOnDestroy() {

  }
}
