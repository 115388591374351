import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {InterceptorProviders} from './core/interceptors';

import {RouterModule} from '@angular/router';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RoutesModule} from './routes/routes.module';


import {LocationStrategy, PathLocationStrategy, registerLocaleData} from '@angular/common';
import zh from '@angular/common/locales/zh';
import {ServiceModule} from './core/service/service.module';
import {GuardModule} from './core/guard/guard.module';
import {DirectiveModule} from './core/directive/directive.module';

registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    // 路由模块
    RouterModule,
    // 浏览器模块
    BrowserModule,
    // AntDes模块
    NgZorroAntdModule,
    // 表单模块
    FormsModule,
    // 响应式表单模块
    ReactiveFormsModule,
    // 网络模块
    HttpClientModule,
    // 浏览器动画模块
    BrowserAnimationsModule,
    // 自定义路由模块
    RoutesModule,
    // 通用服务
    ServiceModule,
    // 路由守卫
    GuardModule,
    // 指令
    DirectiveModule
  ],
  providers: [
    // 配置全局拦截器
    InterceptorProviders,
    // html5导航
    {provide: LocationStrategy, useClass: PathLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
