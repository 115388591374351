import {Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'layout-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {

  @Input() title: string = '';

  constructor(private el: ElementRef) { }

  ngOnInit() {
  }

}
